












import BuffMenu from "@/components/Report/Menu.vue";

import {
    Component,
    Vue,
} from 'vue-property-decorator';


import {
    User
} from '@/store/user'


import {
    Core
} from '@/store/core'
import _ from 'lodash'
import Map from '@/components/Maps/MapView2.vue'
@Component({
    components: {
        Map
    },
    computed: {

    }
})
export default class MapData extends Vue {

    user: any = User.user
    profile: any = User.profile

    width: any = this.$vuetify.breakpoint.width
    rawFarm:any = []
    locations:any = []
    response:boolean = false;
    async created() {
        this.rawFarm = await Core.getHttp(`/user/buffalo/farm/`)
        let map = _.map(this.rawFarm ,(r)=>{
            let rawMap = (r.location).split(',')
            return  {'Latitude':rawMap[0] ,'Longitude':rawMap[1] }
        })
        this.locations = map
       this.response = true;
    }

  
}
